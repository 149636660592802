import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/machineTypes");
  }
  get(id) {
    return axios.get(`/machineTypes/${id}`);
  }
  create(data) {
    return axios.post("/machineTypes", data);
  }
  update(id, data) {
    return axios.put(`/machineTypes/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/machineTypes/${id}`);
  }
  deleteAll() {
    return axios.delete(`/machineTypes`);
  }
  findByTitle(title) {
    return axios.get(`/machineTypes?title=${title}`);
  }
  changeStatus(id) {
    return axios.get(`changemachineTypesStatus/${id}`);
  }
}
export default new DataService();